<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card" v-if="assessments !== null">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Assessments</h3>
            <p class="mb-0">Manage your assessments.</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end"></div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="assessmentFilters.search == null || assessmentFilters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    assessmentFilters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="assessmentFilters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
        <div class="col-sm-4">
          <div class="w-100 d-flex align-items-center justify-content-end"></div>
        </div>
      </div>
      <div class="table-responsive">
        <table role="table" class="table table-hover table-condensed">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col">Description</th>
              <th role="columnheader" scope="col" class="text-center">Status</th>
              <!-- <th role="columnheader" scope="col" class="text-center">Questionnaires</th> -->
              <!-- <th role="columnheader" scope="col" class="text-center">Risk Value</th> -->
              <th role="columnheader" scope="col" class="text-center">Progress</th>
              <!-- <th role="columnheader" scope="col" class="text-center">Compliance</th> -->
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="assessments && assessments != null && assessments.length > 0">
              <tr
                v-for="(assessment, index) in assessments"
                :key="assessment._id"
                role="row"
                class="cursor-pointer"
                @click="handleAssessmentClick(assessment._id)"
              >
                <th>
                  {{
                    (assessmentFilters.page - 1) * assessmentPagination.perPage +
                    index +
                    1
                  }}
                </th>
                <td role="cell" style="max-width: 20vw">
                  <span class="font-weight-bold">{{ assessment.title }}</span>
                </td>

                <td role="cell" style="max-width: 20vw">
                  {{ assessment.description }}
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-badge :variant="getStatusColor(assessment.status)">{{
                      getStatusNumber(assessment.status)
                    }}</b-badge>
                  </div>
                </td>

                <!-- <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-avatar
                      :text="assessment.total_questionnaires.toString()"
                      variant="light-primary"
                    />
                  </div>
                </td> -->
                <!-- <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="assessment.risk_value.toString()"
                    variant="light-success"
                    v-if="assessment.risk_value"
                  />
                  <b-avatar text="0" variant="light-success" v-else />
                </div>
              </td> -->

                <td role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <!-- <donut-bar
                      :percentage="Number(assessment.completion.toFixed(2))"
                      :displayValue="assessment.completion + '%'"
                      size="xsm"
                      :uniqueId="assessment._id + 2"
                      :colorsReversed="true"
                    /> -->
                    <progress-badge
                      :min="0"
                      :max="100"
                      title="Progress"
                      :value="assessment.completion"
                    />
                  </div>
                </td>

                <!-- <td role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <progress-badge
                      :min="0"
                      :max="100"
                      title="Compliance"
                      :value="getCompliance(assessment.gap)"
                    />
                  </div>
                </td> -->
                <!-- <td role="cell">
                <div class="d-flex align-items-center justify-content-center w-100">
                  <b-button
                    @click.stop="handleEditClick(assessment._id)"
                    variant="primary"
                    size="sm"
                    ><span class="align-middle"
                      ><feather-icon icon="EditIcon" class="mr-50" /><span
                        class="align-middle"
                        >Edit</span
                      ></span
                    ></b-button
                  >
                </div>
              </td> -->
              </tr>
            </template>

            <template v-else>
              <empty-table-section title="Assessments Empty">
                <template #content>
                  <p class="text-center">
                    It seems there are no assessments at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>

        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-pagination
            class="mt-2"
            v-model="assessmentFilters.page"
            :per-page="assessmentPagination.perPage"
            :total-rows="assessmentPagination.totalPages * assessmentPagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ProgressBadge from "../../../components/ProgressBadge";
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import VendorMixins from "../../../mixins/VendorMixins";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  components: {
    ProgressBadge,
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    EmptyTableSection,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      selectedAssessmentId: null,
      assessments: null,
      isUserVendor: false,
      vendorUsers: null,
      vendorUsersFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
      assessmentFilters: {
        page: 1,
      },
      assessmentPagination: {
        perPage: 10,
        totalPages: 1,
      },
      from: 1,
    };
  },

  mixins: [ResponseMixins, TaskMixins, UtilsMixins, QuestionnaireMixins, VendorMixins],

  watch: {
    vendorUsersFilters: {
      handler: function (newValue) {
        this.getVendorUsersAndSetData(newValue);
      },
      deep: true,
    },
    assessmentFilters: {
      handler: function (newValue) {
        this.getAssessmentsOfVendorAndSetData(
          newValue,
          this.$store.state.app.user.vendor_id
        );
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }

      this.getAssessmentsOfVendorAndSetData(
        this.assessmentFilters,
        this.$store.state.app.user.vendor_id
      );
    },

    getCompliance(gap) {
      return 100 - gap;
    },

    handleEditClick(assessmentId) {
      this.selectedAssessmentId = assessmentId;
      this.openEditAssessmentSidebar();
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    handleAssessmentClick(id) {
      this.$router.push(`/vendorassessments/${id}`);
    },

    getAssessmentsOfVendorAndSetData(filters, vendorId) {
      this.showOverlay = true;
      this.getAssessmentsOfVendor(filters, vendorId)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.from = res.data.data.from;
          this.assessmentFilters.page = res.data.data.current_page;
          this.assessmentPagination.perPage = res.data.data.per_page;
          this.assessmentPagination.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewAssessmentClick() {
      this.openAddAssessmentSidebar();
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAddAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
    },
    openAddAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
      });
    },
    closeEditAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
    },
    openEditAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
